.emptyBase {
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon{
    display: flex;
    margin-bottom: var(--p12);
  }
}

.emptyImage {
  width: var(--p128);
  height: var(--p128);
  background-size: contain;
}