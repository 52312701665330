.image {
  background-color: var(--theme-background-color-primary);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
}

.imageError {
  width: var(--p24);
  height: var(--p24);
}

.imageStoryBookStyle {
  width: var(--p192);
  height: var(--p160);
  border-radius: var(--radius-md);
  display: flex;
  justify-content: center;
  align-items: center;
}
