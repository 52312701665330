.accessCheckWrapper {
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  line-height: var(--p20);
  margin-top: var(--p20);
}
.emptyWrapper{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .pageNotFound{
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    .title{
      margin-bottom: var(--p12);
    }
    .description{
      margin-bottom: var(--p24);
      color: var(--theme-text-color-tertiary)
    }
  }
}
